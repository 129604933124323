import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import enTranslations from './locales/en.json';
import deTranslations from './locales/de.json';

export const resources = {
    en: {
        translation: enTranslations,
    },
    de: {
        translation: deTranslations,
    },
} as const;

const storedLanguage = localStorage.getItem('preferredLanguage');
const initialLanguage =
    storedLanguage && ['en', 'de'].includes(storedLanguage)
        ? storedLanguage
        : 'en';

i18n.use(initReactI18next).init({
    resources,
    lng: initialLanguage,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
